<template>
  <div>
    <div v-if="hasImage" class="parent">
      <div class="child1">
        <img
          class="px-0 img"
          :src="societe ? societe.carte : '@/assets/parc.jpg'"
        />
      </div>
      <div class="child1 child2 text-uppercase">DATE : {{ date }}</div>
      <div class="child1 child3">
        <h1 class="text-large text-uppercase font-weight-black">
          BL : {{ toPrint.bl }}
        </h1>
        <br />
        <h1 class="text-large text-uppercase font-weight-black">
          CH : {{ toPrint.numero_chassis }}
        </h1>
      </div>
    </div>

    <div
      class="mx-2 text-center"
      style="text-align: center; margin: 10%"
      v-if="!hasImage"
    >
      <div border="right" colored-border type="error" elevation="2">
        VOUS DEVEZ DEFINIR UNE IMAGE DE CARTE IMPORTATEUR
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toPrint: {},
      societe: {},
      hasImage: true,
    };
  },

  mounted() {
    let data = this.$router.currentRoute.query;
    this.toPrint = data;
    this.$nextTick(() => {
      this.societeData();

      // this.$nuxt.$loading.start()
      // setTimeout(() => {
      //   this.$nuxt.$loading.finish()
      //   window.print()
      // }, 500)
    });
  },

  computed: {
    date() {
      var today = new Date();
      var date =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();
      return date;
    },
  },

  methods: {
    async societeData() {
      await this.$http
        .get("/societes")
        .then((response) => {
          this.societe = response.data.data[0];
        })
        .then(() => {
          setTimeout(() => {
            if (this.societe && this.societe.carte) {
              window.print();
            } else {
              this.hasImage = false;
            }
          }, 500);
        });
    },
  },
};
</script>

<style lang="scss">
@page {
    size: landscape;
}

.text-large {
  font-size: 2em;
}
.parent {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .child1 {
    position: absolute;
    .img {
      width: 100%;
      max-width: 100vw;
    }
  }

  .child2 {
    right: 0;
    margin-top: 10px !important;
    padding-right: 20px !important;
    font-size: 1.5em;
    font-weight: bold;
  }

  .child3 {
    top: 100px;
    z-index: 1;
    font-size: 4em !important;
    border-radius: 10px;
  }
}
</style>